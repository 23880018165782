import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Routes as RoutesDom,
  Route,
} from 'react-router-dom';

//MyAppFitness
import LoginPage from './loginV2';
// import RelacaoAlunos from './relacao-alunosV2';
// import RelacaoTreinos from './relacao-treinosV2';
// import PerfilPessoalPersonal from './perfil-pessoalV2/personal';
// import PerfilDoAluno from './perfil-pessoalV2/perfil-do-aluno';
// import EsqueceuSenha from './esqueceu-senhaV2';
// import MontagemTreinos from './montagem-treinosV2';
// import AlunoConexao from './aluno-conexaoV2/aluno-conexao';
// import RelacaoTreinosAluno from './relacao-treinos-alunoV2';
// import PerfilPessoalVisaoAluno from './perfil-pessoalV2/visao-aluno';
// import PerfilDoPersonal from './perfil-pessoalV2/perfil-do-personal';
// import ChooseCreateType from './choose-create-typeV2';
// import Periodizacao from './periodizacao';
// import Planos from './planos'

//GPFT
// import LoginPage from './login';
//import RelacaoAlunos from './relacao-alunos';
//import RelacaoTreinos from './relacao-treinos';
//import PerfilPessoalPersonal from './perfil-pessoal/personal';
//import PerfilDoAluno from './perfil-pessoal/perfil-do-aluno';
//import EsqueceuSenha from './esqueceu-senha';
//import MontagemTreinos from './montagem-treinos';
//import AlunoConexao from './aluno-conexao/aluno-conexao';
//import RelacaoTreinosAluno from './relacao-treinos-aluno';
//import PerfilPessoalVisaoAluno from './perfil-pessoal/visao-aluno';
//import PerfilDoPersonal from './perfil-pessoal/perfil-do-personal';
//import ChooseCreateType from './choose-create-type';

import { useStore } from '../hooks';
// import BaseExercicios from './base-exercicios';
import PoliticaPrivacidade from './politica-de-privacidade';
import TermoUsoPersonal from './termo-uso-personal';
import DeleteAccount from './delete-account'
// import Users from './users'
import Feedback from './feedback';
import Notifications from './notifications';
import Agendar from './agendar'
import ConfirmarAgendamento from './confirmar'
import PoliticaPrivacidadeTattooApp from './politica-de-privacidade-2';
// import Tutorial from './tutorial';

const Routes = () => {
  const store = useStore();
  let user = store.getUser();

  useEffect(() => {
    user = store.getUser();
    console.log('user', user)
  }, [store, store.state]);

  return (
    <Router>
			<RoutesDom>
				<Route path="*" element={<LoginPage />} exact />
				{/* <Route path="/" element={<LoginPage />} exact />
				<Route path="/cadastro" element={<ChooseCreateType />} exact />
				<Route path="/esqueceu-senha" element={<EsqueceuSenha />} exact /> */}
				<Route path="/politica-privacidade" element={<PoliticaPrivacidade />} exact />
				<Route path="/politica-privacidade2" element={<PoliticaPrivacidadeTattooApp />} exact />
				<Route path="/termo-uso" element={<TermoUsoPersonal />} exact />
				<Route path="/delete-account" element={<DeleteAccount />} exact />
				{
					user.user ?
					<>
					{/* <Route path="/users" element={<Users />} exact /> */}
					<Route path="/feedback" element={<Feedback />} exact />
					<Route path="/notifications" element={<Notifications />} exact />
					</>

					:
					<></>
				}
				<Route path="/agendar" element={<Agendar />} exact />
				<Route path="/confirmar-agendamento" element={<ConfirmarAgendamento />} exact />

				{/* {user && user.role === 'personal' && (
					<>
						<Route path="/conexoes" element={<RelacaoAlunos />} exact />
						<Route path="/perfil-conexao" element={<PerfilDoAluno />} exact />
						<Route path="/exercicios" element={<BaseExercicios />} exact />
						<Route
							path="/relacao-treinos"
							element={<RelacaoTreinos />}
							exact
						>
							<Route path=":idTreino" element={<RelacaoTreinos />}
							exact />
						</Route>
						<Route path="/perfil" element={<PerfilPessoalPersonal />} exact />
						<Route
							path="/montagem-treinos"
							element={<MontagemTreinos />}
							exact
						>
							<Route path=":idTreino" element={<MontagemTreinos />} />
						</Route>
						<Route path="/periodizacao" element={<Periodizacao />} exact />
						<Route path="/planos" element={<Planos />} exact />
						<Route path="/tutorial" element={<Tutorial />} exact />
					</>
				)}

					{user && user.role === 'aluno' && (
					<>
						<Route path="/conexoes" element={<AlunoConexao />} exact />
						<Route
							path="/relacao-treinos"
							element={<RelacaoTreinosAluno />}
							exact
						>
						</Route>
						<Route
							path="/perfil-conexao"
							element={<PerfilDoPersonal />}
							exact
						/>
						<Route
							path="/perfil"
							element={<PerfilPessoalVisaoAluno />}
							exact
						/>
					</>
				)} */}
				{/* {role === null && (
					<>
						<Route path="*" element={<Redirect />} />
					</>
				)} */}
			</RoutesDom>
    </Router>
  );
};

export default Routes;
