import React, { useEffect, useMemo, useState } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import UsersApi from '../../services/users';
import { MenuItem, InputLabel, FormControl, Select, Typography } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import moment from 'moment-timezone';

const phoneMask = (value) => {
  if (!value) return '';
  value = value.replace(/\D/g, '');
  value = value.replace(/(\d{2})(\d)/, '($1) $2');
  value = value.replace(/(\d)(\d{4})$/, '$1-$2');
  return value;
};

const daysWeekly = ['Domingo', 'Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

const daysTercaSabado = ['Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

const daysSegundaSabado = ['Segunda', 'Terça', 'Quarta', 'Quinta', 'Sexta', 'Sábado'];

const Agendar = () => {
  const [activeStep, setActiveStep] = useState(0);
  const [name, setName] = useState('');
  const [phone, setPhone] = useState('');
  const [data, setData] = useState(new Date());
  const [datas, setDatas] = useState([]);
  const [servicos, setServicos] = useState([]);
  const [produtos, setProdutos] = useState([]);
  const [agendas, setAgendas] = useState([]);
  const [hours, setHours] = useState([]);
  const [hoursToSelect, setHoursToSelect] = useState([]);
  const [timezone, setTimezone] = useState('America/Sao_Paulo');
  const [searchParams] = useSearchParams();
  const [loading, setLoading] = useState(true);
  const [clientes, setClientes] = useState([]);
  const [userToUpdate, setUserToUpdate] = useState();
  const [lancamentos, setLancamentos] = useState([]);
  const [dadoAgendado, setDadoAgendado] = useState();
  const [servicosSelecionados, setServicosSelecionados] = useState([]);
  const [produtosParaSelecao, setProdutosParaSelecao] = useState([]);
  const [view, setView] = useState('');
  const [duracaoServico, setDuracaoServico] = useState();
  const [isPremiumAvailable, setPremiumAvailable] = useState();
  const [horariosBloqueados, setHorariosBloqueados] = useState([]);

  const loadData = async () => {
    try {
      const mail = searchParams.get('key');
      const response = await UsersApi.getByEmail(mail);
      setLoading(false);
      setServicos(JSON.parse(response[0].servicos));
      setAgendas(JSON.parse(response[0].agendas));
      const hoursParsed = JSON.parse(response[0].hours);
      setHours(hoursParsed);
      setClientes(JSON.parse(response[0].clientes));
      setTimezone(response[0].timezone);
      setUserToUpdate(response[0]);
      setLancamentos(JSON.parse(response[0].lancamentos));
    } catch (e) {
      console.log(e);
    }

    const nome = localStorage.getItem('name');
    const telefone = localStorage.getItem('phone');

    if (nome && telefone) {
      setName(nome);
      setPhone(telefone);
      setActiveStep(1);
    }

    const dataAgendar = JSON.parse(localStorage.getItem('@agendado'));
    if (dataAgendar) {
      if (new Date(dataAgendar.date) >= new Date()) {
        setDadoAgendado(dataAgendar);
        setActiveStep(4);
      }
    }
  };

  const checkPremiumAvailability = async () => {
    const mail = searchParams.get('key');
    const responsePremium = await UsersApi.getPremiumData(mail);
    if (responsePremium[0].isPremium && responsePremium[0]?.daysLeftPremium > 0) {
      setPremiumAvailable(true);
    } else {
      setPremiumAvailable(false);
    }
  };

  const fetchProducts = async () => {
    try {
      const mail = searchParams.get('key');
      const response = await UsersApi.getByEmail(mail);
      setProdutos(JSON.parse(response[0].produtos));
      setProdutosParaSelecao(
        JSON.parse(response[0].produtos)
          ?.filter((x) => x.price > 0 && x.quantity > 0)
          .map((p) => ({ ...p, quantity: 0 }))
      );
    } catch (error) {
      console.log('fetchProducts error :', error);
    }
  };

  useEffect(() => {
    checkPremiumAvailability();
    fetchProducts();
    loadData();
  }, []);

  useEffect(() => {
    if (!hours.length) return;

    const dates = [];
    const today = new Date();

    for (let i = 0; i < 60; i++) {
      const date = new Date(today);
      date.setDate(today.getDate() + i);
      const dayName = daysWeekly[date.getDay()];

      // Verificar se existe configuração ativa para o dia
      const hasActiveConfig = hours.some(
        (h) => h.checked && (h.day === dayName || h.day.includes(dayName) || h.day.includes('à')) // Suporta intervalos
      );

      if (hasActiveConfig) dates.push(date);
    }

    setDatas(dates);
  }, [hours]);

  // Função para gerar slots de horários com base na configuração de `hours`
  const generateTimeSlots = () => {
    const slots = [];
    const interval = 30;
    const currentDate = moment.tz(data, timezone).startOf('day');

    // Obter configuração do dia específico (ex: "Segunda", "Terça", etc.)
    const targetDay = daysWeekly[data.getDay()]; // daysWeekly é seu array de dias

    // Encontrar configuração ativa para o dia atual
    const dayConfig = hours.find(
      (h) => h.checked && (h.day === targetDay || h.day.includes(targetDay)) // Suporta intervalos como "Segunda à Sábado"
    );

    // Usar fallback seguro se não encontrar configuração
    const [startHour, startMinute] = dayConfig?.initialHour?.split(':') || [9, 0];
    const [endHour, endMinute] = dayConfig?.finalHour?.split(':') || [17, 0];

    const startTime = currentDate.clone().set({
      hour: parseInt(startHour),
      minute: parseInt(startMinute),
    });

    const endTime = currentDate.clone().set({
      hour: parseInt(endHour),
      minute: parseInt(endMinute),
    });

    while (startTime.isSameOrBefore(endTime)) {
      slots.push(startTime.clone());

      startTime.add(interval, 'minutes');
    }

    return slots;
  };

  // useEffect(() => {
  //   if (!hours.length) return;

  //   setHoursToSelect([]);
  // }, [hours]);

  useEffect(() => {
    if (servicosSelecionados.length > 0) {
      const agendasNaData = agendas
        .filter((x) => {
          const agendaDate = moment.tz(x.hour, timezone).format('YYYY-MM-DD');
          const targetDate = moment.tz(data, timezone).format('YYYY-MM-DD');
          return agendaDate === targetDate;
        })
        .map((agenda) => ({
          ...agenda,
          hourEnd: agenda.hourEnd || moment(agenda.hour).add(30, 'minutes').toDate(),
        }));

      setHorariosBloqueados(agendasNaData.map((a) => moment(a.hour).format('HH:mm')));

      const concatenatedDuration = servicosSelecionados.map((service) => service.duration).join('');

      const regex = /(\d+h)|(\d+min)/g;
      const matches = concatenatedDuration.match(regex);
      let totalMinutes = 0;

      if (matches) {
        matches.forEach((match) => {
          if (match.includes('h')) {
            totalMinutes += parseInt(match.replace('h', ''), 10) * 60;
          } else if (match.includes('min')) {
            totalMinutes += parseInt(match.replace('min', ''), 10);
          }
        });
      }

      setDuracaoServico(totalMinutes);

      const possibleSlots = generateTimeSlots();

      const isSlotAvailable = (slotStart) => {
        const slotEnd = moment(slotStart).add(totalMinutes, 'minutes');

        return !agendasNaData.some((agenda) => {
          const agendaStart = moment.tz(agenda.hour, timezone);
          const agendaEnd = moment.tz(agenda.hourEnd, timezone);

          const finalAgendaEnd = agendaEnd.isSameOrAfter(agendaStart) ? agendaEnd : agendaStart.add(1, 'minute');

          return (
            slotStart.isBetween(agendaStart, finalAgendaEnd, null, '[)') ||
            slotEnd.isBetween(agendaStart, finalAgendaEnd, null, '(]') ||
            (slotStart.isBefore(agendaStart) && slotEnd.isAfter(finalAgendaEnd))
          );
        });
      };

      const horariosLiberados = possibleSlots.filter(isSlotAvailable);

      setHoursToSelect(horariosLiberados);
    }
  }, [servicosSelecionados, data, timezone, hours]);

  const produtosSelecionados = useMemo(() => produtosParaSelecao?.filter((product) => product.quantity > 0), [produtosParaSelecao]);

  const nomesServicos = useMemo(() => servicosSelecionados?.map((service) => service.name).join(',') ?? '', [servicosSelecionados]);

  const nomesProdutos = useMemo(() => produtosSelecionados?.map((product) => product.name).join(',') ?? '', [produtosSelecionados]);

  const precoTotalServicos = useMemo(
    () => servicosSelecionados?.reduce((accumulator, service) => accumulator + service.price, 0),
    [servicosSelecionados]
  );

  const precoTotalProdutos = useMemo(
    () => produtosSelecionados?.reduce((accumulator, product) => accumulator + product.price * parseInt(product.quantity, 10), 0),
    [produtosSelecionados]
  );

  const idsServicosSelecionados = useMemo(() => servicosSelecionados?.map((service) => service.id).join(',') ?? '', [servicosSelecionados]);

  const handleChange = (event, nextView) => {
    setView(nextView);
  };

  const handleChangeServico = (event, arrayServicosSelecionados) => {
    setServicosSelecionados(arrayServicosSelecionados);
  };

  const handlePhone = (value) => {
    setPhone(value);
    localStorage.setItem('phone', value);
  };

  const handleName = (value) => {
    setName(value);
    localStorage.setItem('name', value);
  };

  const generateId = () => {
    const caracteres = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    let id = '';
    for (let i = 0; i < 16; i++) {
      const randomIndex = Math.floor(Math.random() * caracteres.length);
      id += caracteres[randomIndex];
    }
    return id;
  };

  const changeProductQuantity = (id, value) => {
    const newProducts = produtosParaSelecao.map((product) => {
      if (product.id === id) {
        return { ...product, quantity: value };
      }
      return product;
    });

    setProdutosParaSelecao(newProducts);
  };

  const isProductOutOfStock = (product) => {
    // if(metaEdit?.id !== undefined) return false
    const p = produtos.filter((pr) => pr.id === product.id)[0];
    return p.quantity < product.quantity || p.quantity === 0;
  };

  const isUsingProductOutOfStock = useMemo(() => produtosSelecionados.filter(isProductOutOfStock).length > 0, [produtosSelecionados]);

  const handleDadosAgendamento = () => {
    const dataInit = hoursToSelect[view];
    const dataEnd = moment(dataInit).add(duracaoServico, 'minutes');
    const serviceText = servicosSelecionados.length > 1 ? 'Serviços: ' + nomesServicos : 'Serviço: ' + nomesServicos;
    const productText =
      produtosSelecionados.length > 0 ? (produtosSelecionados.length > 1 ? 'Produtos: ' + nomesProdutos : 'Produto: ' + nomesProdutos) : '';
    const ok = window.confirm(
      'Confirmar agendamento? \n' + 'Data: ' + moment(dataInit).format('DD/MM/YYYY - HH:mm') + `\n ${serviceText}`
    );

    if (!ok) return;
    const obj = {
      date: dataInit,
      hour: dataInit,
      hourEnd: dataEnd,
      service: nomesServicos,
      products: produtosSelecionados,
    };

    let category = nomesServicos;

    if (nomesProdutos) category += ',' + nomesProdutos;

    //LAST RELEASE
    const item = {
      category: category,
      calendar: dataInit,
      value: precoTotalServicos + precoTotalProdutos,
      received: true,
      id: generateId(),
      formOfPayment: '',
    };

    const cleanPhone = (phone) => phone.replace(/\D/g, '').replace('55', '').replace('+55', '');

    let responseClient = clientes
      .filter((x) => x.cellphone)
      .find((x) => cleanPhone(phone).includes(cleanPhone(x.cellphone)) || cleanPhone(x.cellphone).includes(cleanPhone(phone)));

    const clientesAdd = JSON.parse(JSON.stringify(clientes));

    if (!responseClient) {
      responseClient = {
        id: generateId(),
        name: name,
        address: '',
        cellphone: phone,
        active: true,
      };

      clientesAdd.push(responseClient);
      setClientes(clientesAdd);
    }

    const agendamento = {
      date: dataInit,
      hour: dataInit,
      hourEnd: dataEnd,
      observation: '',
      title: '',
      releaseId: item.id,
      releaseData: item,
      serviceId: idsServicosSelecionados,
      products: produtosSelecionados,
      clientId: responseClient?.id,
      repeat: 'unico',
      id: generateId(),
    };

    const agendasAdd = JSON.parse(JSON.stringify(agendas));
    agendasAdd.push(agendamento);

    const lancamentosAdd = JSON.parse(JSON.stringify(lancamentos));

    lancamentosAdd.push(item);

    const newUser = JSON.parse(JSON.stringify(userToUpdate));
    newUser.agendas = JSON.stringify(agendasAdd);
    newUser.clientes = JSON.stringify(clientesAdd);
    newUser.lancamentos = JSON.stringify(lancamentosAdd);
    newUser.produtos = JSON.stringify(
      produtos.map((p) => {
        const product = produtosSelecionados.find((pr) => pr.id === p.id);
        if (product) {
          return { ...p, quantity: p.quantity - product.quantity };
        }
        return p;
      })
    );

    UsersApi.postUsuario(newUser);

    setDadoAgendado(obj);

    localStorage.setItem('@agendado', JSON.stringify(obj));
    setActiveStep(4);

    const messages = [];
    messages.push({
      to: newUser.pushToken,
      title: 'Novo cliente agendado!',
      body: `Cliente: ${name} - ${moment(dataInit).format('DD/MM/YYYY - hh:mm')} \n ${nomesServicos} \n ${nomesProdutos}`,
    });

    UsersApi.sendNotifications(messages);
  };

  const steps = ['Dados', 'Serviço', 'Horários', produtos.length > 0 ? 'Produtos' : 'Concluir agendamento'];

  const step1 = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 20, justifyContent: 'center', alignItems: 'center', width: '77%' }}>
        <h3 style={{ color: '#fff' }}>Preencha seus dados</h3>
        <TextField
          inputProps={{ maxLength: 30 }}
          InputLabelProps={{ style: { color: '#bababa' } }}
          value={name}
          onChange={(event) => handleName(event.target.value)}
          color="success"
          sx={{
            width: '70%',
            backgroundColor: '#1C1F1E',
            input: {
              '&::placeholder': {
                color: '#727272',
                opacity: 1,
              },
            },
            '& .MuiInputBase-root': {
              '& input': {
                color: '#fff',
              },
            },
          }}
          variant="outlined"
          id="outlined-basic"
          label="Nome"
        />
        <TextField
          inputProps={{ maxLength: 16 }}
          value={phoneMask(phone)}
          onChange={(event) => handlePhone(event.target.value)}
          InputLabelProps={{ style: { color: '#bababa' } }}
          color="success"
          sx={{
            width: '70%',
            backgroundColor: '#1C1F1E',
            input: {
              '&::placeholder': {
                color: '#727272',
                opacity: 1,
              },
            },
            '& .MuiInputBase-root': {
              '& input': {
                color: '#fff',
              },
            },
          }}
          variant="outlined"
          id="outlined-basic"
          label="Celular"
        />
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '70%' }}>
          <Button
            disabled={!name || !phone}
            sx={{
              color: '#fff',
              background: '#72a2f9',
              borderColor: '#72a2f9',
              fontWeight: 'bold',
              ':hover': { color: '#fff', background: '#72a2f9' },
            }}
            color="success"
            fullWidth
            onClick={() => setActiveStep(1)}
            variant="contained"
          >
            Continuar
          </Button>
        </div>
      </div>
    );
  };

  const step2 = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 20, justifyContent: 'center', alignItems: 'center', width: '77%' }}>
        <h3 style={{ color: '#fff' }}>Selecione o Serviço</h3>
        <ToggleButtonGroup
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2,
            alignItems: 'center',
            width: '100%',
            minWidth: 800,
            marginTop: 5,
            marginBottom: 5,
          }}
          orientation="vertical"
          value={servicosSelecionados}
          onChange={handleChangeServico}
        >
          {servicos
            .filter((x) => x.price > 0)
            .map((serv, index) => {
              return (
                <ToggleButton key={index} value={serv} aria-label="list" sx={{ width: '30%' }}>
                  {serv.icon} {serv.name} -{' '}
                  {serv.price.toLocaleString('pt-br', {
                    style: 'currency',
                    currency: 'BRL',
                  })}
                </ToggleButton>
              );
            })}
        </ToggleButtonGroup>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 20, alignItems: 'center', width: '70%' }}>
          <Button
            disabled={servicosSelecionados.length < 1}
            sx={{
              color: '#fff',
              background: '#72a2f9',
              borderColor: '#72a2f9',
              fontWeight: 'bold',
              ':hover': { color: '#fff', background: '#72a2f9' },
            }}
            fullWidth
            color="success"
            onClick={() => setActiveStep(2)}
            variant="contained"
          >
            Continuar
          </Button>
          <Button
            sx={{
              color: '#72a2f9',
              borderColor: '#72a2f9',
              fontWeight: 'bold',
              ':hover': { color: '#fff', background: '#72a2f9' },
            }}
            fullWidth
            onClick={() => setActiveStep(0)}
            variant="outlined"
          >
            Voltar
          </Button>
        </div>
      </div>
    );
  };

  const step3 = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', placeItems: 'center', width: '77%' }}>
        <h3 style={{ color: '#fff' }}>Selecione o Horário</h3>
        <FormControl fullWidth>
          <InputLabel id="demo-simple-select-label" style={{ color: '#72a2f9' }}>
            Data
          </InputLabel>
          <Select
            color="success"
            displayEmpty
            value={moment(data) // Converte o Date para Moment
              .format('DD/MM/YYYY')}
            label="Data"
            onChange={(e) => {
              setData(e.target.value);
            }}
            placeholder="test"
            renderValue={(selected) => {
              if (selected === undefined || selected === ' ') {
                return <Typography color={'#727272'}>Tipo selecionado</Typography>;
              }
              return selected;
            }}
            inputProps={{ 'aria-label': 'Without label' }}
            sx={{
              backgroundColor: '#1C1F1E',
              color: '#fff',
              input: {
                color: '#727272',
                '&::placeholder': {
                  color: '#727272',
                  opacity: 1,
                },
              },
            }}
          >
            {datas.map((x, index) => {
              return (
                <MenuItem key={index} value={x}>{`
									${moment(x) // Converte o Date para Moment
                    .format('DD/MM/YYYY')} - ${daysWeekly[x.getDay()]}`}</MenuItem>
              );
            })}
          </Select>
        </FormControl>

        {!hoursToSelect?.length ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              color: '#fff',
              fontSize: 28,
              textAlign: 'center',
            }}
          >
            Não há horários disponíveis para o dia selecionado
          </div>
        ) : (
          <ToggleButtonGroup
            sx={{
              display: 'flex',
              flexWrap: 'wrap',
              alignItems: 'center',
              justifyContent: 'center',
              gap: 1,
              maxWidth: 600,
              marginTop: 5,
              marginBottom: 5,
            }}
            orientation="horizontal"
            value={view}
            exclusive
            onChange={handleChange}
          >
            {hoursToSelect
              .filter((slot) => {
                const slotTime = moment(slot).format('HH:mm');
                return !horariosBloqueados.includes(slotTime);
              })
              .map((x, index) => (
                <ToggleButton value={index} aria-label="module" key={x}>
                  {moment(x).format('HH:mm')}
                </ToggleButton>
              ))}
          </ToggleButtonGroup>
        )}
        <div style={{ display: 'flex', flexDirection: 'column', gap: 20, alignItems: 'center', width: '70%', marginTop: 10 }}>
          <Button
            disabled={!view && view !== 0}
            sx={{
              color: '#fff',
              background: '#72a2f9',
              borderColor: '#72a2f9',
              fontWeight: 'bold',
              ':hover': { color: '#fff', background: '#72a2f9' },
            }}
            color="success"
            fullWidth
            onClick={() => setActiveStep(3)}
            variant="contained"
          >
            Continuar
          </Button>
          <Button
            sx={{
              color: '#72a2f9',
              borderColor: '#72a2f9',
              fontWeight: 'bold',
              ':hover': { color: '#fff', background: '#72a2f9' },
            }}
            fullWidth
            onClick={() => setActiveStep(1)}
            variant="outlined"
          >
            Voltar
          </Button>
        </div>
      </div>
    );
  };

  const step4 = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, justifyContent: 'center', alignItems: 'center', width: '77%' }}>
        <h3 style={{ color: '#fff' }}>Selecione o Produto</h3>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 50, alignItems: 'center', width: '100%' }}>
          {produtos.length > 0 &&
            produtosParaSelecao.map((product) => {
              return (
                <div key={product.id} style={{ display: 'flex', flexDirection: 'column', placeItems: 'center', gap: 20, width: '100%' }}>
                  <hr style={{ width: '100%', maxWidth: 500 }} />
                  <img src={product.image} style={{ aspectRatio: 1, width: '12%', minWidth: 130 }} />
                  <div style={{ display: 'flex', flexWrap: 'wrap', gap: 15, placeItems: 'center', placeContent: 'center' }}>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        fontSize: 14,
                        backgroundColor: '#fff',
                        borderRadius: 5,
                        paddingLeft: 12,
                        paddingRight: 12,
                        height: 60,
                        flex: 1,
                        minWidth: 200,
                      }}
                    >
                      {product.name.toUpperCase()} -{' '}
                      {product.price.toLocaleString('pt-br', {
                        style: 'currency',
                        currency: 'BRL',
                      })}
                    </div>
                    <TextField
                      inputProps={{ maxLength: 30 }}
                      type="number"
                      label={isProductOutOfStock(product) ? 'SEM ESTOQUE' : 'Quantidade'}
                      error={isProductOutOfStock(product)}
                      value={product.quantity}
                      onChange={(event) => changeProductQuantity(product.id, event.target.value)}
                      color="success"
                      sx={{
                        flex: 1,
                        minWidth: 100,
                        backgroundColor: '#1C1F1E',
                        label: {
                          color: '#bababa',
                        },
                        input: {
                          '&::placeholder': {
                            color: '#727272',
                            opacity: 1,
                          },
                        },
                        '& .MuiInputBase-root': {
                          '& input': {
                            color: '#fff',
                          },
                        },
                      }}
                      variant="outlined"
                      id="outlined-basic"
                    />
                  </div>
                </div>
              );
            })}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 20, alignItems: 'center', width: '70%', marginTop: 10 }}>
          <Button
            disabled={isUsingProductOutOfStock}
            sx={{
              color: '#fff',
              background: '#72a2f9',
              borderColor: '#72a2f9',
              fontWeight: 'bold',
              ':hover': { color: '#fff', background: '#72a2f9' },
            }}
            color="success"
            style={{ marginTop: 10 }}
            fullWidth
            onClick={handleDadosAgendamento}
            variant="contained"
          >
            Continuar
          </Button>
          <Button
            sx={{
              color: '#72a2f9',
              borderColor: '#72a2f9',
              fontWeight: 'bold',
              ':hover': { color: '#fff', background: '#72a2f9' },
            }}
            style={{ marginTop: 10 }}
            fullWidth
            onClick={() => setActiveStep(1)}
            variant="outlined"
          >
            Voltar
          </Button>
        </div>
      </div>
    );
  };

  const step5 = () => {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', gap: 10, justifyContent: 'center', alignItems: 'center', width: '77%' }}>
        <h3 style={{ color: '#fff' }}>Finalizar agendamento</h3>
        <div style={{ display: 'flex', flexDirection: 'column', gap: 20, alignItems: 'center', width: '70%', marginTop: 10 }}>
          <Button
            disabled={isUsingProductOutOfStock}
            sx={{
              color: '#fff',
              background: '#72a2f9',
              borderColor: '#72a2f9',
              fontWeight: 'bold',
              ':hover': { color: '#fff', background: '#72a2f9' },
            }}
            color="success"
            style={{ marginTop: 10 }}
            fullWidth
            onClick={handleDadosAgendamento}
            variant="contained"
          >
            Continuar
          </Button>
          <Button
            sx={{
              color: '#72a2f9',
              borderColor: '#72a2f9',
              fontWeight: 'bold',
              ':hover': { color: '#fff', background: '#72a2f9' },
            }}
            style={{ marginTop: 10 }}
            fullWidth
            onClick={() => setActiveStep(2)}
            variant="outlined"
          >
            Voltar
          </Button>
        </div>
      </div>
    );
  };

  const stepConcluded = () => {
    return (
      <div
        style={{
          textAlign: 'center',
          justifyContent: 'center',
          alignContent: 'center',
        }}
      >
        <div>
          <h3 style={{ color: '#fff' }}>Agendamento realizado com sucesso!</h3>
        </div>
        <div>
          <CheckCircleIcon style={{ fontSize: 100 }} />
        </div>
        <div>
          <h3 style={{ color: '#fff' }}>Horario: {moment(dadoAgendado?.date).format('DD/MM/YYYY - hh:mm')}</h3>
          <h3 style={{ color: '#fff' }}>Serviço: {dadoAgendado?.service}</h3>
          {produtos.length > 0 && <h3 style={{ color: '#fff' }}>Produto: {dadoAgendado?.products.map((p) => p.name).join(',')}</h3>}
        </div>
        <Button
          sx={{
            color: '#72a2f9',
            borderColor: '#72a2f9',
            fontWeight: 'bold',
            ':hover': { color: '#fff', background: '#72a2f9' },
          }}
          style={{ marginTop: 10 }}
          fullWidth
          onClick={() => setActiveStep(1)}
          variant="outlined"
        >
          Agendar outro serviço.
        </Button>
      </div>
    );
  };

  const renderStep = () => {
    if (activeStep === 0) return step1();
    if (activeStep === 1) return step2();
    if (activeStep === 2) return step3();
    if (activeStep === 3) {
      if (produtos.length > 0) {
        return step4();
      } else {
        return step5();
      }
    }
    return stepConcluded();
  };

  return (
    <div>
      {loading ? (
        <CircularProgress />
      ) : !isPremiumAvailable ? (
        <>
          <h5 style={{ color: '#fff' }}>
            Link de agendamento online indisponível. Renovação de plano necessária por parte do profissional.
          </h5>
        </>
      ) : (
        <div style={{ display: 'flex', flexDirection: 'column', placeItems: 'center', maxWidth: 1440, width: '95%', margin: 'auto' }}>
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel style={{ color: '#fff !important' }}>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>
          </Box>

          {renderStep()}
        </div>
      )}
    </div>
  );
};

export default Agendar;
