import React from 'react';
import './App.css';
import { createTheme, ThemeProvider } from '@mui/material';
import Routes from './views/routes';
import { Provider } from './context';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { initializeApp } from 'firebase/app';
import { getAnalytics } from 'firebase/analytics';

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: 'AIzaSyD-1KJPQSMkjrGoKPgoEx5cPP6pq__ALiU',
  authDomain: 'myappfitness-537d9.firebaseapp.com',
  projectId: 'myappfitness-537d9',
  storageBucket: 'myappfitness-537d9.appspot.com',
  messagingSenderId: '88123848531',
  appId: '1:88123848531:web:999c4c337e742bfc0153a6',
  measurementId: 'G-EE0CBPMX2T'
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
// eslint-disable-next-line no-unused-vars
const analytics = getAnalytics(app);

const theme = createTheme({
  palette: {
    primary: {
      light: '#33bfbb',
      main: '#0098D7',
      dark: '#007a76',
      contrastText: '#fff',
      backText: '#727272',
    },
    secondary: {
      light: '#ffcf33',
      main: '#F2A001',
      dark: '#b28900',
      contrastText: '#000',
      goText: '#F3A611',
    },
  }
});

function App() {
  return (
    <Provider>
      <ThemeProvider theme={theme}>
        <Routes />
        <ToastContainer />
      </ThemeProvider>
    </Provider>
  );
}

export default App;
